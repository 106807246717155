import { useEffect, useState } from 'react';
import _ from 'lodash'
import { apiFetch, isSurveyComplete, getRespondentDescriptionFormatted } from '../utils';
import ResultsRadar from './ResultsRadar';
import ResultsTable from './ResultsTable';
import './Results.css';
import ConsumerDutyTable from './ConsumerDutyTable';

const consumerDutyUrl = 'https://www.thepfs.org/news-insight/consumer-duty'
const developmentPlannerUrl = 'https://www.cii.co.uk/media/10128649/my-development-planner.xlsx'
const findLearningUrl = 'https://www.ciigroup.org/en/professional-map/individuals/find-learning/'

//note score = W (needs work), R (room for improvement), G (going great)
// the table below shows, for each importance level,
// the mininum ability to score a 'G' and the maximum ability for a 'W'
const scoreThresholdsByImportance = {
  1: { G: 1, W: 0 },
  2: { G: 2, W: 0 },
  3: { G: 3, W: 1 },
  4: { G: 4, W: 2 },
  5: { G: 5, W: 3 },
}

const calculateStatementScore = (importance, ability) => {
  if (!importance || !ability) return "X"
  const thresholds = scoreThresholdsByImportance[importance]
  switch (true) {
    case ability >= thresholds.G: return 'G'
    case ability <= thresholds.W: return 'W'
    default: return 'R'
  }
}

const calculateCompetencyScore = (statementScores) => {
  const counts = _.countBy(statementScores)
  const percentG = (counts.G || 0) / statementScores.length * 100
  const percentW = (counts.W || 0) / statementScores.length * 100
  switch (true) {
    case percentG >= 80 && percentW === 0: return 'G'
    case percentG < 40 && percentW >= 60: return 'W'
    default: return 'R'
  }
}

const buildCompetency = (competencies, { competency, statements }) => {
  const statementList = statements.map(x => ({
    ...x,
    score: calculateStatementScore(x.importance, x.ability)
  }))
  return {
    code: competency,
    name: competencies.find(x => x.code === competency).name,
    ability: _.meanBy(statementList, 'ability'),
    importance: _.meanBy(statementList, 'importance'),
    score: calculateCompetencyScore(statementList.map(x => x.score)),
    statements: statementList
  }
}

const buildResultsData = (competencies, statements) =>
  statements.map(x => buildCompetency(competencies, x))

const calculateStatementWeights = (statementOutcomeWeights) => {
  if (!statementOutcomeWeights) {
    return { weights: [], totalWeight: 0 }
  }
  return {
    weights: statementOutcomeWeights,
    totalWeight: _.sumBy(statementOutcomeWeights, 'weight')
  }
}

const addWeightsToStatements = (statements, outcomeWeights) => {
  const weightsByStatement = Object.fromEntries(outcomeWeights.map(x => [x.statement, x.outcomes]))
  // console.log(weightsByStatement)
  return statements.map(competency => ({
    ...competency,
    statements: competency.statements.map(statement => ({
      ...statement,
      ...calculateStatementWeights(weightsByStatement[statement.id])
    }))
  }))
}

const includeStatementInConsumerDutyReport = statement => {
  const statementIsImportant = statement.totalWeight >= 52
  const abilityThreshold = statementIsImportant ? 3 : 2
  return statement.totalWeight && statement.ability <= abilityThreshold
}

const getColor = weight => {
  if (!weight) return 0
  const thresholds = [24, 17, 9, 1]
  const idx = thresholds.findIndex(x => x <= weight)
  return thresholds.length - idx
}

/*
statements is a list of (competency + list of statements)
*/
const buildConsumerDutyData = (competencies, statements, outcomeWeights) => {
  const competenciesByCode = Object.fromEntries(competencies.map(x => [x.code, x]))
  statements = addWeightsToStatements(statements, outcomeWeights)
  statements = statements.map(competency => ({
    ...competency,
    statements: competency.statements.filter(includeStatementInConsumerDutyReport)
  }))
  statements = statements.filter(competency => competency.statements.length)
  statements = statements.map(competency => ({
    ...competency,
    statements: competency.statements.map(statement => ({
      ...statement,
      weights: statement.weights.map(weight => ({
        ...weight,
        color: getColor(weight.weight)
      }))
    }))
  }))
  statements = statements.map(competency => ({
    ...competency,
    competency: competenciesByCode[competency.competency],
  }))
  return statements
}

export default function Results({ respondent, competencies }) {
  const [resultsData, setResultsData] = useState(null)
  const [surveyComplete, setSurveyComplete] = useState(false)
  const [consumerDutyData, setConsumerDutyData] = useState(null)

  useEffect(
    () => {
      let isMounted = true;
      (async () => {
        const [statements, outcomeWeights] = await Promise.all(
          [
            apiFetch('statements'),
            apiFetch('outcomeweights'),
          ]
        )
        if (isMounted) {
          setSurveyComplete(isSurveyComplete(statements))
          setConsumerDutyData(buildConsumerDutyData(competencies, statements, outcomeWeights))
          setResultsData(buildResultsData(competencies, statements))
        }
      }
      )()
      return () => { isMounted = false }
    },
    [competencies]
  )

  const printToPdf =
    <div class="print-tools hideforprint">
      <p>To save a record of your results, click on the button below then select "Print to PDF" or "Save to PDF".</p>
      <a class="member-button-link" href="/" onClick={e => { e.preventDefault(); window.print() }}>Save as PDF</a>
    </div>


  return (
    <div>
      {!resultsData
        ? "loading..."
        : <>
          {!surveyComplete &&
            <h1>Error - not all questions have been answered - you should never see this message</h1>
          }
          <div className="member-title text-md-center pt-5 pb-3 pb-md-4 mb-0">
            <div className="container container-alt">
              <div className="row">
                <div className="col-12">
                  <h2 className="pb-3 pb-md-4 mb-0">{getRespondentDescriptionFormatted(respondent, competencies)}</h2>
                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div className="member-text text-md-center pb-5">
            <div className="container container-alt">
              <div className="col-12">
                <p>It’s time to analyse your results, set your development goals and create your personal development plan.
                </p>
                <p>This report is provided in two parts: first your overall self-assessment results,
                  and secondly your Consumer Duty development areas.
                </p>
                <p>NB for those outside the UK, this regulation may not apply to you, however you may find it useful to align with UK good practice.
                  You can find out more about the Consumer Duty <a href={consumerDutyUrl} target="_blank" rel="noreferrer">here</a>.
                </p>
                <p>Why not use
                  our <a href={developmentPlannerUrl} target="_blank" rel="noreferrer">Development Planner</a> to
                  map your way to excellence, or share the results with your line
                  manager as the basis of a development conversation?
                </p>
              </div>
            </div>
          </div>
          <div className="row w-100">
            <div className="results-heading">
              <h3>Your overall self-assessment results</h3>
            </div>
          </div>
          {resultsData.length === 1
            ? <div>{printToPdf}</div>
            : <div className="row row-alt w-100 mb-3">
              <div className="col-md-6 d-flex justify-content-center">
                <ResultsRadar resultsData={resultsData} />
              </div>
              <div className="container container-alt col-md-6 mb-4 px-md-4 results-description">
                <p>Your results are represented in the chart as the comparison between how important a competency is
                  to your role (blue area) versus your ability to perform (orange area), across each one of the 11 competencies.
                </p>
                <p>
                  The scores are calculated as the average of your responses on a scale of 1 to 5, where 1 is weak and 5 is strong.
                  The competency with the largest gap is a good target for you to focus.
                  You can use the detailed breakdown below to gain further insights on your strengths and your development areas.
                </p>
                <p>
                  Please note that Behaviours and Ethics and Compliance are all considered of critical importance
                  and as such, all have an importance score of 5 by default.
                </p>
                {printToPdf}
              </div>
            </div>
          }
          <div className="row w-100 m-0">
            <div className="row m-0 p-0 justify-content-center">
              <div class="row w-100 mb-5 results-subtext">
                <p>Your results are based on the gap between how important something is for
                  your role and how well you think you are performing in that area. The bigger
                  the gap, the larger the development need:</p>
                <ul>
                  <li>Needs work indicates areas that are important for your role, in which
                    your performance is weak</li>
                  <li>Room for improvement indicates areas that are important for your role,
                    in which your performance is average</li>
                  <li>Doing great indicates either areas that are important for your role where
                    your performance is strong, or areas that are not important for your role and
                    as such there is no development need</li>
                </ul>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <ResultsTable resultsData={resultsData} />
              </div>
            </div>
          </div>
          <div className="row w-100 m-0">
            <div className="row m-0 p-0 justify-content-center">
              <div class="row w-100 mt-5 mb-5 duty-infowrapper">
                <div class="results-inforow mb-5">
                  <div class="row w-100">
                    <div class="results-heading">
                      <h4 class="mb-4 pb-3">Your Consumer Duty development areas</h4>
                    </div>
                  </div>
                  {consumerDutyData.length
                    ? <>
                      <div class="row result-rowinfo">
                        <div class="col-lg-12 mb-1">
                          <p>Below you will find the competencies that have a causal link with each of the four
                            Consumer Duty outcomes where you have scored low. You can use this summary to help
                            prioritise your development areas in line with the Consumer Duty.
                          </p>
                          <p>
                            The colours illustrate the extent to which a competency drives each one of the four outcomes.
                          </p>
                        </div>
                      </div>
                      <div class="col-12 d-flex justify-content-center">
                        <ConsumerDutyTable resultsData={consumerDutyData} />
                      </div>
                    </>
                    : <div class="row result-rowinfo">
                      <div class="col-lg-12 mb-1">
                        <p>Well done! Your self-assessment indicates you have developed the right mix of competencies that will
                          enable you to meet the needs and expectations of customers in a fair and responsible way.
                        </p>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="member-button pb-5">
            <div className="container container-alt">
              <div className="row">
                <div className="col-12 text-center">
                  <a className="member-button-link mx-md-3 mb-3" href={developmentPlannerUrl} target="_blank" rel="noreferrer" >
                    Download Development Planner
                  </a>
                  <a className="member-button-link mx-md-3 mb-3" href={findLearningUrl} target="_blank" rel="noreferrer" >
                    Find Learning
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </div >
  )
}
