import doinggreat from '../img/dial_doing_great.svg';
import needswork from '../img/dial_needs_work.svg';
import forimprovement from '../img/dial_for_improvement.svg';

const competencyIconLookup = {
    'W': { img: needswork, title: "Needs work" },
    'R': { img: forimprovement, title: "Room for improvement" },
    'G': { img: doinggreat, title: "Doing great" },
}

const getDial = score => {
    const dial = competencyIconLookup[score]
    return <img src={dial.img} alt={dial.title} title={dial.title} />
}

export default function ResultsTable({ resultsData }) {
    return <div className="row w-100 mb-4 results-infowrapper">
        {resultsData.map(competency =>
            <div key={`c_${competency.code}`} className="results-inforow mb-5">
                <div className="result-rowheading">
                    <h4 className="mb-4 pb-3">{competency.name}</h4>
                </div>
                <div className="row result-rowinfo">
                    <div className="col-lg-3 col-md-12 mb-4 mb-lg-0 px-2 dial-column text-center">
                        {getDial(competency.score)}
                    </div>
                    <div className="col-lg-3 col-md-4 pe-md-5 mb-3">
                        <h5 className="icon-title icon-work">Needs work</h5>
                        <ul>
                            {competency.statements.filter(s => s.score === 'W').map(statement =>
                                <li key={`s_${statement.id}`}>{statement.text}</li>
                            )}
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 pe-md-5 mb-3">
                        <h5 className="icon-title icon-improvement">Room for improvement</h5>
                        <ul>
                            {competency.statements.filter(s => s.score === 'R').map(statement =>
                                <li key={`s_${statement.id}`}>{statement.text}</li>
                            )}
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 pe-md-5 mb-3">
                        <h5 className="icon-title icon-great">Doing great</h5>
                        <ul>
                            {competency.statements.filter(s => s.score === 'G').map(statement =>
                                <li key={`s_${statement.id}`}>{statement.text}</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        )}
    </div>
}
