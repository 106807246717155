import _ from 'lodash'
import { Radar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    defaults,
} from 'chart.js';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

defaults.font.size = 12;

export default function ResultsRadar({ resultsData }) {
    const [labels, ability, importance] = _.unzip(resultsData.map(x => ([x.name, x.ability, x.importance])))

    const radarData = {
        labels,
        datasets: [
            {
                label: 'ABILITY',
                data: ability,
                backgroundColor: 'rgba(173, 93, 58, 0.1)',
                borderColor: 'rgba(173, 93, 58, 1)',
                color: '#000',
            },
            {
                label: 'IMPORTANCE',
                data: importance,
                backgroundColor: 'rgba(96, 141, 162, 0.1)',
                borderColor: 'rgba(96, 141, 162, 1)',
                color: '#000',
            },
        ]
    }
    const radarOptions = {
        scales: {
            r: { min: 0, max: 5 }
        },
        plugins: {
            legend: {
                labels: {
                    // This more specific font property overrides the global property
                    color: '#676767',
                    boxWidth: 9,
                    boxHeight: 9,
                    usePointStyle: true,
                    pointStyle: 'circle',
                    font: {
                        size: 12,
                        family: "'Open Sans', sans-serif",
                        weight: 600,
                    }
                }
            }
        }
    }

    return (
        <div className="res-radar-wrapper">
            <div className="res-radar-inner">
                <Radar options={radarOptions} data={radarData} />
            </div>
        </div>
    )
}
