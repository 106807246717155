import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Survey as SurveyJs, StylesManager, defaultBootstrapCss } from 'survey-react'
import { apiFetch, getRespondentDescriptionFormatted } from './utils'
import './Survey.css';

StylesManager.applyTheme("bootstrap")

const abilityRateValues = [
  { value: 1, text: "Mininal" },
  { value: 2, text: "Weak" },
  { value: 3, text: "Adequate" },
  { value: 4, text: "Good" },
  { value: 5, text: "Strong" },
]

const importanceRateValues = [
  { value: 1, text: "Not at all important" },
  { value: 2, text: "Slightly important" },
  { value: 3, text: "Important" },
  { value: 4, text: "Very important" },
  { value: 5, text: "Critical" },
]

export default function Survey({ respondent, competencies }) {
  const [statements, setStatements] = useState(null)
  const navigate = useNavigate();

  //Custom survey classes
  defaultBootstrapCss.navigationButton = "sv-button-link";
  defaultBootstrapCss.progressText = "sv-progress-text show-text";
  defaultBootstrapCss.progressTextInBar = "inbar sv-hidden";
  defaultBootstrapCss.headerText = "sv-header__text";
  defaultBootstrapCss.pageDescription = "sv-description sv-page__description";
  defaultBootstrapCss.rating.root = "sv-rating";
  defaultBootstrapCss.rating.item = "sv-rating__item";
  defaultBootstrapCss.rating.selected = "sv-rating__item--selected";
  defaultBootstrapCss.navigation.complete = "sv-btn sv-footer__complete-btn";
  defaultBootstrapCss.navigation.prev = "sv-btn sv-footer__prev-btn";
  defaultBootstrapCss.navigation.next = "sv-btn sv-footer__next-btn";
  defaultBootstrapCss.navigation.start = "sv-btn sv-footer__start-btn";
  defaultBootstrapCss.navigation.preview = "sv-btn sv-footer__preview-btn";
  defaultBootstrapCss.navigation.edit = "sv-btn sv-footer__edit-btn";

  useEffect(
    () => {
      let isMounted = true;
      (async () => {
        const result = await apiFetch(`statements`)
        if (result) {
          if (isMounted) {
            setStatements(result)
          }
        }
      })()
      return () => { isMounted = false }
    },
    []
  )

  function reformatAnswers(data) {
    const statementsById = statements && Object.fromEntries(statements.flatMap(c => c.statements.map(s => [s.id, s])))
    let result = {}
    Object.entries(data).forEach(([questionName, value]) => {
      const [id, key] = questionName.split(".")
      if (!result[id]) {
        const statementImportance = statementsById[id].statementImportance
        result[id] = statementImportance ? { importance: statementImportance } : {}
      }
      result[id][key] = value
    })
    return Object.entries(result).map(([k, v]) => ({ statement: Number(k), ...v }))
  }

  async function sendDataToServer(sender, final) {
    // note onPartialSend is not called for the last page so there is no double-save or race condition
    const data = reformatAnswers(sender.data)
    await apiFetch('answers', data)
    /*
     * note: surveyJs has a mechanism for redirecting after the results have been saved using navigateToUrl 
     * and options.showDataSavingSuccess(). However, because that redirect is done outside the react app,
     * it causes the app to reload, so instead we do it ourselves
     */
    if (final) {
      navigate("../results")
    }
  }

  const surveyData = statements && {
    completedHtml: "Generating results, please wait...",
    showProgressBar: "top",
    showQuestionNumbers: "off",
    requiredText: "",
    pages: statements.map(c => {
      const competency = competencies.find(x => x.code === c.competency)
      return {
        name: c.competency,
        title: competency.name,
        description: competency.description,
        questions: c.statements.map(s => ({
          type: "panel",
          title: `${s.text}`,
          elements: [
            {
              type: "rating",
              isRequired: true,
              name: `${s.id}.ability`,
              title: "My ability to perform",
              defaultValue: s.ability || null,
              rateValues: abilityRateValues,
            },
            ...(s.statementImportance
              ? []
              : [{
                type: "rating",
                isRequired: true,
                name: `${s.id}.importance`,
                title: "Importance to my work",
                defaultValue: s.importance || null,
                rateValues: importanceRateValues,
              }]
            )
          ]
        }))
      }
    })
  }

  return (
    <div>
      {!statements
        ? "loading..."
        : <div>
          <div className="member-title text-md-center pt-5 pb-3 pb-md-4 mb-0">
            <div className="container container-alt">
              <div className="row">
                <div className="col-12">
                  <h2 className="pb-3 pb-md-4 mb-0">{getRespondentDescriptionFormatted(respondent, competencies)}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="App">
            <div className="row w-100 m-0">
              <div className="row m-0 p-0 justify-content-center">
                <SurveyJs
                  json={surveyData}
                  sendResultOnPageNext={true}
                  onPartialSend={x => sendDataToServer(x, false)}
                  onComplete={x => sendDataToServer(x, true)}
                />
              </div>
            </div>
          </div>
        </div>}
    </div>
  );
}
