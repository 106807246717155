// if data is specified it does a POST, otherwise a GET
export async function apiFetch(url, data) {
    const result = await fetch(`/api/${url}`,
        {
            method: data ? "POST" : "GET",
            headers: { 'Content-Type': 'application/json' },
            body: data ? JSON.stringify(data) : undefined
        }
    )
    if (result.ok) {
        return (await result.json()).results
    }
    console.error("Request failed", url, data, result)
    alert('request failed - see console');
    throw new Error('request failed');
}

function getRespondentDescriptionValues({ band, jobFunction }, competencies) {
    const competency = competencies.find(x => x.code === jobFunction)
    return {
        industry: (competency.sector === 'I' ? "Insurance" : "Personal Finance"),
        competency: competency.name,
        band: band ? "band " + band : ""
    }
}

export function getRespondentDescriptionText(respondent, competencies) {
    const { industry, competency, band } = getRespondentDescriptionValues(respondent, competencies)
    return `${industry}: ${competency} ${band}`
}

export function getRespondentDescriptionFormatted(respondent, competencies) {
    const { industry, competency, band } = getRespondentDescriptionValues(respondent, competencies)
    return <>{industry}: <strong>{competency} {band}</strong></>
}

export const isSurveyComplete = statements =>
    statements.every(c => c.statements.every(s => s.ability && s.importance))
