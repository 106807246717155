import { useEffect, useState } from 'react';
import { useSearchParams, Link } from "react-router-dom";
import { apiFetch, getRespondentDescriptionText, getRespondentDescriptionFormatted, isSurveyComplete } from './utils';

const STATUS_LOADING = 0
const STATUS_NEW_RESPONDENT = 1
const STATUS_EXISTING_RESPONDENT = 2
const STATUS_RESPONDENT_DIFFERENT = 3
const STATUS_EXISTING_RESPONDENT_COMPLETE = 4
const STATUS_RESPONDENT_DIFFERENT_COMPLETE = 5
const STATUS_PARAM_ERROR = 6

async function validateParams(searchParams, competencies) {
  // sector must be I or F
  const sector = searchParams.get('sector')?.toUpperCase()
  if (!sector) throw new TypeError("sector must be specified")
  if (['I', 'F'].indexOf(sector) === -1) throw new TypeError("Sector must be I or F")

  // jobfunction must correspond to a technical competency within that sector
  const jobFunction = searchParams.get('jobfunction')?.toUpperCase();
  if (!jobFunction) throw new TypeError("jobfunction must be specified")
  const competency = competencies.find(x => x.sector === sector && x.code === `${sector}.${jobFunction}`)
  if (!competency) throw new TypeError(`job function ${jobFunction} not found in sector ${sector}`)
  if (competency.competencyType !== 'T') throw new TypeError(`job function ${jobFunction} is not a technical competency`)

  // band must be 1-4 for core competencies and is always assumed to be 0 otherwise
  const bandStr = searchParams.get('band')
  let band
  if (!competency.core) {
    band = 0
  } else {
    if (!bandStr) throw new TypeError("band must be specified")
    band = Number(bandStr)
    if (!(Number.isInteger(band) && band >= 1 && band <= 4)) throw new TypeError("band must be a number between 1 and 4")
  }
  return {
    band,
    jobFunction: `${sector}.${jobFunction}`,
  }
}

function getRespondentStatus(respondent, surveyParams, surveyComplete) {
  if (!respondent.started) {
    return STATUS_NEW_RESPONDENT
  }
  if (respondent.band !== surveyParams.band ||
    respondent.jobFunction !== surveyParams.jobFunction) {
    return surveyComplete ? STATUS_RESPONDENT_DIFFERENT_COMPLETE : STATUS_RESPONDENT_DIFFERENT
  }
  return surveyComplete ? STATUS_EXISTING_RESPONDENT_COMPLETE : STATUS_EXISTING_RESPONDENT
}

async function validateRespondent(searchParams) {
  const competencies = await apiFetch('competencies')

  let surveyParams
  try {
    surveyParams = await validateParams(searchParams, competencies)
  } catch (e) {
    if (e instanceof TypeError) {
      return {
        status: STATUS_PARAM_ERROR,
        errorMessage: e.message
      }
    } else {
      throw e
    }
  }

  const respondent = await apiFetch('respondent/verify', surveyParams)
  let surveyComplete
  if (respondent.started) {
    const statements = await apiFetch(`statements`)
    surveyComplete = isSurveyComplete(statements)
  } else {
    surveyComplete = false
  }

  return {
    status: getRespondentStatus(respondent, surveyParams, surveyComplete),
    surveyParams,
    respondent,
    competencies,
  }
}

export default function Intro({ callback }) {
  const [searchParams] = useSearchParams();
  const [state, setState] = useState({ status: STATUS_LOADING })

  useEffect(
    () => {
      let isMounted = true;
      (async () => {
        const result = await validateRespondent(searchParams)
        if (isMounted) {
          setState(result)
        }
      })()
      return () => { isMounted = false }
    },
    [callback, searchParams]
  )

  const continueClick = e => {
    e.preventDefault()
    callback({ respondent: state.respondent, competencies: state.competencies, navigateUrl: '/survey' })
  }

  const resultsClick = e => {
    e.preventDefault()
    callback({ respondent: state.respondent, competencies: state.competencies, navigateUrl: '/results' })
  }

  const resetClick = async (e, completed) => {
    e.preventDefault()
    const respondent = await apiFetch(`respondent/reset?deleteanswers=${!completed}`, state.surveyParams)
    callback({ respondent, competencies: state.competencies, navigateUrl: '/survey' })
  }

  const yesClick = (e, completed) => {
    e.preventDefault()
    setState({
      ...state,
      status: completed ? STATUS_EXISTING_RESPONDENT_COMPLETE : STATUS_EXISTING_RESPONDENT,
      surveyParams: state.respondent,
    })
  }

  const noClick = async (e, completed) => {
    e.preventDefault()
    const respondent = await apiFetch(`respondent/reset?deleteanswers=${!completed}`, state.surveyParams)
    setState({ ...state, status: STATUS_NEW_RESPONDENT, respondent })
  }

  const respondentExists = !([STATUS_PARAM_ERROR, STATUS_LOADING].includes(state.status))
  const previousDetails = respondentExists && getRespondentDescriptionText(state.respondent, state.competencies)
  const newDetails = respondentExists && getRespondentDescriptionText(state.surveyParams, state.competencies)
  const minutes = respondentExists && (state.surveyParams.band ? 20 : 3)

  const intro = respondentExists && <div className="member-title text-md-center pt-5 pb-3 pb-md-4 mb-0">
    <div className="container container-alt">
      <div className="row">
        <div className="col-12">
          <h2 className="pb-3 pb-md-4 mb-0">{getRespondentDescriptionFormatted(state.surveyParams, state.competencies)}</h2>
          <hr />
        </div>
      </div>
    </div>
  </div>

  return <div className="row w-100 m-0">
    {state.status === STATUS_LOADING &&
      <p>loading...</p>
    }
    {state.status === STATUS_PARAM_ERROR && <>
      <p>invalid URL parameters</p>
      <p>{state.errorMessage}</p>
    </>}
    {state.status === STATUS_NEW_RESPONDENT && <>
      {intro}
      <div className="member-text text-md-center pb-5">
        <div className="container container-alt">
          <div className="col-12">
            <p>Please allow up to {minutes} minutes to complete your self-assessment.</p>
            <p>You don't need to complete it in one go. So long as you return to it using
              the same device and browser on which you started, you’ll be able to resume
              without losing your responses. Please note that clearing your browser
              history will also clear your saved responses.</p>
          </div>
        </div>
      </div>
      <div className="member-button pb-5">
        <div className="container container-alt">
          <div className="row">
            <div className="col-12 text-center">
              <Link to="/" className="member-button-link mx-md-3 and mb-3" onClick={continueClick}>Start self-assessment</Link>
            </div>
          </div>
        </div>
      </div>
    </>}
    {state.status === STATUS_EXISTING_RESPONDENT && <>
      {intro}
      <div className="member-text text-md-center pb-5">
        <div className="container container-alt">
          <div className="col-12">
            <p>You have previously started a self-assessment for {newDetails}.</p>
            <p>Please click on 'resume self-assessment' to continue taking your self-assessment
              keeping your saved responses, or 'reset self-assessment' to clear your answers
              and restart a new self-assessment.</p>
          </div>
        </div>
      </div>
      <div className="member-button pb-5">
        <div className="container container-alt">
          <div className="row">
            <div className="col-12 text-center">
              <Link to="/" className="member-button-link mx-md-3 and mb-3" onClick={continueClick}>Resume self-assessment</Link>
              <Link to="/" className="member-button-link mx-md-3 and mb-3" onClick={e => resetClick(e, false)}>Reset self-assessment</Link>
            </div>
          </div>
        </div>
      </div>
    </>}
    {state.status === STATUS_EXISTING_RESPONDENT_COMPLETE && <>
      {intro}
      <div className="member-text text-md-center pb-5">
        <div className="container container-alt">
          <div className="col-12">
            <p>You have already completed a self-assessment for {newDetails}.</p>
            <p>Please click on 'view report' to view your saved results; click on 'review answers'
              to review your responses for each question; or click on 'reset' to clear your
              answers and restart a new self-assessment for the same function and band.</p>
          </div>
        </div>
      </div>
      <div className="member-button pb-5">
        <div className="container container-alt">
          <div className="row">
            <div className="col-12 text-center">
              <Link to="/" className="member-button-link mx-md-3 and mb-3" onClick={resultsClick}>View report</Link>
              <Link to="/" className="member-button-link mx-md-3 and mb-3" onClick={continueClick}>Review answers</Link>
              <Link to="/" className="member-button-link mx-md-3 and mb-3" onClick={e => resetClick(e, true)}>Reset self-assessment</Link>
            </div>
          </div>
        </div>
      </div>
    </>}
    {state.status === STATUS_RESPONDENT_DIFFERENT && <>
      {intro}
      <div className="member-text text-md-center pb-5">
        <div className="container container-alt">
          <div className="col-12">
            <p>You have previously started a self-assessment for a different function or band.</p>
            <p>Do you want to resume your saved self-assessment for {previousDetails}? </p>
            <p>Please note that by selecting 'No' you will lose any saved responses as there can
              only be one live self-assessment at a time.</p>
          </div>
        </div>
      </div>
      <div className="member-button pb-5">
        <div className="container container-alt">
          <div className="row">
            <div className="col-12 text-center">
              <Link to="/" className="member-button-link mx-md-3 and mb-3" onClick={e => yesClick(e, false)}>Yes</Link>
              <Link to="/" className="member-button-link mx-md-3 and mb-3" onClick={e => noClick(e, false)}>No</Link>
            </div>
          </div>
        </div>
      </div>
    </>}
    {state.status === STATUS_RESPONDENT_DIFFERENT_COMPLETE && <>
      {intro}
      <div className="member-text text-md-center pb-5">
        <div className="container container-alt">
          <div className="col-12">
            <p>You have previously completed a self-assessment for a different function or band. </p>
            <p>Do you want to view your saved self-assessment for {previousDetails}?</p>
            <p>Please note that by selecting 'No' you will lose any saved responses as there can
              only be one live self-assessment at a time.</p>
          </div>
        </div>
      </div>
      <div className="member-button pb-5">
        <div className="container container-alt">
          <div className="row">
            <div className="col-12 text-center">
              <Link to="/" className="member-button-link mx-md-3 and mb-3" onClick={e => yesClick(e, true)}>Yes</Link>
              <Link to="/" className="member-button-link mx-md-3 and mb-3" onClick={e => noClick(e, true)}>No</Link>
            </div>
          </div>
        </div>
      </div>
    </>}
  </div>
}
