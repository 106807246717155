const outcomes = [
    { name: "Customer Understanding", code: "U" },
    { name: "Price and Value", code: "V" },
    { name: "Products and Services", code: "P" },
    { name: "Consumer Support", code: "S" },
]

const grades = [
    { cssClass: "grade-none", description: "Not directly driving" },
    { cssClass: "grade-little", description: "Driving to a little extent" },
    { cssClass: "grade-some", description: "Driving to some extent" },
    { cssClass: "grade-moderate", description: "Driving to a moderate extent" },
    { cssClass: "grade-great", description: "Driving to a great extent" },
]

export default function ConsumerDutyTable({ resultsData }) {
    return <div class="row w-100 mb-4 results-infowrapper duty-wrapper">
        <div class="results-inforow mb-5">
            <h5 class="statement-title"><strong>KEY:</strong></h5>
            {grades.slice().reverse().map(grade =>
                <div class="row results-keyrow mb-1">
                    <span class={`key-box ${grade.cssClass}`}>
                        <svg width="500" height="25">
                            <rect width="500" height="25" />
                        </svg>
                    </span>
                    <span class="key-description">{grade.description}</span>
                </div>
            )}
        </div>
        {resultsData.map(competency => <>
            <div key={`c_${competency.competency.code}`} className="results-inforow mb-5">
                <div class="result-rowheading">
                    <h4 class="mb-4 pb-3">{competency.competency.name}</h4>
                </div>
                <div class="row result-rowinfo">
                    {competency.statements.map(statement =>
                        <div key={`s_${statement.id}`} class="col-12 mb-3">
                            <div class="col-12 mb-3 d-grid results-grid">
                                <div class="grid-item statement-item">
                                    <p>{statement.text}</p>
                                </div>
                                {outcomes.map((outcome, idx) => {
                                    const weight = statement.weights.find(x => x.outcomeType === outcome.code) || { weight: 0, color: 0 }
                                    return <div class={`grid-item ${idx % 2 ? 'odd' : 'even'}`}>
                                        <h5 class="statement-title">{outcome.name}</h5>
                                        <span class={`col-12 d-block grade-element ${grades[weight.color].cssClass}`}>
                                            <svg width="500" height="25">
                                                <rect width="500" height="25" />
                                            </svg>
                                        </span>
                                    </div>
                                }
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
        )}
    </div >
}

