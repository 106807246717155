import React, { useState, useCallback } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import './App.css';
import Intro from './Intro'
import Survey from './Survey'
import Results from './report/Results'
import 'iframe-resizer/js/iframeResizer.contentWindow.min.js'

export default function App() {
  const navigate = useNavigate();
  const [respondent, setRespondent] = useState(null)
  const [competencies, setCompetencies] = useState(null)

  const setParams = useCallback(
    x => {
      setRespondent(x.respondent)
      setCompetencies(x.competencies)
      navigate(x.navigateUrl)
    },
    [navigate])

  return (
    <div>
      <Routes>
        <Route path='/' element={
          <Intro callback={setParams}></Intro>
        } />
        {competencies
          ? <>
            <Route path="/survey" element={<Survey respondent={respondent} competencies={competencies} />} />
            <Route path="/results" element={<Results respondent={respondent} competencies={competencies} />} />
          </>
          : <Route path="*" element={<p>Invalid URL</p>} />
        }
      </Routes>
    </div>
  );
}
